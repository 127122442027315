import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Collapse from 'react-bootstrap/Collapse';
import { useState } from 'react';
import Backtotop from './Backtotop';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ipaddress } from '../App';

const Our_Services = () => {
    const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  const[first_name,setfirst_name]=useState('')
  const[last_name,setlast_name]=useState('')
  const[email,setemail]=useState('')
  const[phone_number,setphone_number]=useState('')
  const[message,setmessage]=useState('')

  const[load,setload]=useState(false)

  const send_data=()=>{
     setload(true)

     setTimeout(() => {
      send()
     }, 1000);
  }

  const send=()=>{
      // console.log("Hi")
      axios.post(`${ipaddress}/ContactMessageView/?token=sfdhgf`,{
          firstname:first_name,
          lastname:last_name,
          email:email,
          phonenumber:phone_number,
          message:message
      })

      .then((r)=>{
        toast.success('Data sent successfully!',{
            autoClose:2000,
            theme: "colored",
            position:'top-center'
        })
          setload(false)
          setfirst_name('')
      setlast_name('')
      setemail('')
      setphone_number('')
      setmessage('')
      })
      .catch((err)=>{
        //   console.log(err)
          setload(false)
      })
  }

  return (
    <div>
        <div className='animate__animated animate__fadeIn' style={{backgroundColor:'#F2F7EB'}}>
            <Navbar state={"our-services"}/>

            <div className="container mt-5 pb-5 pt-1">
            <div className='mt-5 pt-0 pt-md-4 row m-0'>
                    <p className='mb-4 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/><span className='fw-bold mx-1 mx-md-4'>OUR SERVICES</span><img src={require('../images/download.png')}/></p>
                
                    <p className='font1 about-us-head m-0 text-center mb-5'>Bringing Nature Indoors</p>
                   <div className="col-lg-4">
                    <div className='' style={{height:'380px'}}>
                        <img src={require('../images/service1.jpg')} style={{width:'100%',height:'100%',borderRadius:'15px'}} alt="" />
                    </div>
                   </div>

                   <div className="col-lg-4 mt-5 mt-md-0">
                    <div className='d-flex flex-column align-items-center justify-content-center our-services-sub-div' style={{backgroundColor:'#2FB95D',lineHeight:'28px',borderRadius:'14px',height:'380px'}}>
                        <p className='font2 text-white text-center' style={{fontSize:'25px',fontWeight:600}}>Hydroponic System</p>
                        <p className='font5 text-white text-center mt-2' style={{fontSize:'15px',fontWeight:500,lineHeight:'21px'}}>Grow Hydroponic Flower garden indoors like Marigold, Dahlia, Daisy, Zinnia, Pansy etc...</p>
                        {/* <a href="" className='text-white font5' style={{fontSize:'15px',fontWeight:500,lineHeight:'21px'}}>See more</a> */}
                    </div>
                   </div>

                   <div className="col-lg-4 mt-4 mt-md-0">
                    <div className='' style={{height:'380px'}}>
                        <img src={require('../images/service2.jpg')} style={{width:'100%',height:'100%',borderRadius:'15px'}} alt="" />
                    </div>
                   </div>

                   <div className="col-lg-4 mt-5 mt-md-4">
                    <div className='d-flex flex-column align-items-center justify-content-center our-services-sub-div' style={{backgroundColor:'#2FB95D',lineHeight:'28px',borderRadius:'14px',height:'380px'}}>
                        <p className='font2 text-white text-center px-2' style={{fontSize:'25px',fontWeight:600}}>Hydroponic Indoor Garden</p>
                        <p className='font5 text-white text-center mt-2' style={{fontSize:'15px',fontWeight:500,lineHeight:'21px'}}>Grow Herbs like Coriander, Mint, Basil etc...</p>
                        {/* <a href="" className='text-white font5' style={{fontSize:'15px',fontWeight:500,lineHeight:'21px'}}>See more</a> */}
                    </div>
                   </div>

                   <div className="col-lg-4 mt-4 mt-md-4">
                    <div className='' style={{height:'380px'}}>
                        <img src={require('../images/service3.jpg')} style={{width:'100%',height:'100%',borderRadius:'15px'}} alt="" />
                    </div>
                   </div>

                   <div className="col-lg-4 mt-5 mt-md-4">
                    <div className='d-flex flex-column align-items-center justify-content-center our-services-sub-div' style={{backgroundColor:'#2FB95D',lineHeight:'28px',borderRadius:'14px',height:'380px'}}>
                        <p className='font2 text-white text-center px-2' style={{fontSize:'25px',fontWeight:600}}>Hydroponic Equipment</p>
                        <p className='font5 text-white text-center mt-2' style={{fontSize:'15px',fontWeight:500,lineHeight:'21px'}}>Grow Vegetables Outdoors like Capsicum, Beans, Ladys Finger, Brinjals, Green Chillies etc...</p>
                        {/* <a href="" className='text-white font5' style={{fontSize:'15px',fontWeight:500,lineHeight:'21px'}}>See more</a> */}
                    </div>
                   </div>
                </div>

{/* Layout 2 */}
<div className="row m-0 mt-5 pt-5">
                    <div className="col-md-8 pe-4">
                        <p className='mb-1' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><span className='fw-bold me-4'>WHY</span><img src={require('../images/download.png')} alt="" /></p>
                        <p className='about-us-head font1 m-0'>Choose Us?</p>
                        <p className='font2' style={{color:'#999999',lineHeight:'29.6px',fontSize:'16px',fontWeight:400}}>Our hydroponic systems simplify indoor gardening for everyone. Experience the Bloomindoor difference!</p>

                        <div className='mt-3 pe-4'>
                        <div className='d-flex align-items-center p-3 pt-2' style={{borderRadius:'10px'}}>
                                <img src={require('../images/service6.png')} alt="" />
                                <div className='ms-3'>
                                    <p className='fw-bold font3 m-0 mb-2'>Innovative Design</p>
                                    <span className='text-secondary font4 d-block'>Sleek hydroponic systems that blend seamlessly with any decor, adding beauty and functionality to your space.</span>
                                    {/* <a href="" style={{color:'#2FB95D',fontSize:'12px'}}>Read more...</a> */}
                                </div>
                            </div>

                            <div className='d-flex align-items-center p-3 pt-2' style={{borderRadius:'10px'}}>
                                <img src={require('../images/service6.png')} alt="" />
                                <div className='ms-3'>
                                <p className='fw-bold font3 m-0 mb-2'>Effortless Gardening</p>
                                    <span className='text-secondary font4 d-block'>Enjoy hassle-free indoor gardening with our system's easy setup and minimal maintenance, making it a fun and accessible hobby for all.</span>
                                    {/* <a href="" style={{color:'#2FB95D',fontSize:'12px'}}>Read more...</a> */}
                                </div>
                            </div>

                            <div className='d-flex align-items-center p-3 pt-2' style={{borderRadius:'10px'}}>
                                <img src={require('../images/service6.png')} alt="" />
                                <div className='ms-3'>
                                <p className='fw-bold font3 m-0 mb-2'>Eco-Friendly</p>
                                    <span className='text-secondary font4 d-block'>Sustainable gardening with reduced water usage and no need for pesticides, promoting a healthier environment.</span>
                                    {/* <a href="" style={{color:'#2FB95D',fontSize:'12px'}}>Read more...</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mt-4 mt-md-0" style={{position:'relative'}}>
                        <img src={require('../images/service5.jpg')} style={{width:'100%',position:'absolute',top:'30px',left:'-20px',zIndex:2,height:'519px',borderRadius:'25px'}} alt="" />
                        <img src={require('../images/service4.png')} style={{width:'100%',position:'absolute',top:'0px',left:'10px'}} alt="" />
                    </div>
                </div>



{/* Layout 3 */}
<div className="row m-0 our-service-div mt-5 pb-4">

<div className="col-md-5" style={{position:'relative',marginTop:'70px'}}>
                        <img src={require('../images/service7.jpg')} style={{width:'90%',position:'absolute',top:'40px',left:'30px',zIndex:2,height:'540px',borderRadius:'25px'}} alt="" />
                        <img src={require('../images/service4.png')} style={{width:'90%',position:'absolute',top:'0px',left:'0px',height:'540px'}} alt="" />
                    </div>

                    <div className="col-md-7 ps-4" style={{marginTop:'70px'}}>
                        <p className='mb-1' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><span className='fw-bold me-4'>HOW</span><img src={require('../images/download.png')} alt="" /></p>
                        <p className='about-us-head font1 m-0'>It Works</p>
                        <p className='font2' style={{color:'#999999',lineHeight:'29.6px',fontSize:'16px',fontWeight:400}}>Bloomindoor offers a user-friendly experience from initial inquiry to post-delivery assistance, ensuring you feel confident in managing your hydroponic garden.</p>

                        <div className='mt-3 pe-4'>
                        <div className='d-flex align-items-center py-2' style={{borderRadius:'10px'}}>
                                <img src={require('../images/service6.png')} alt="" />
                                <div className='ms-3'>
                                    <p className='fw-bold font3 m-0 mb-2'>Initial Consultation</p>
                                    <span className='text-secondary font4 d-block'>Receive tailored advice and an introduction to hydroponic gardening to kickstart your journey with all the essential insights.</span>
                                    {/* <a href="" style={{color:'#2FB95D',fontSize:'12px'}}>Read more...</a> */}
                                </div>
                            </div>

                            <div className='d-flex align-items-center py-2 mt-3' style={{borderRadius:'10px'}}>
                                <img src={require('../images/service6.png')} alt="" />
                                <div className='ms-3'>
                                <p className='fw-bold font3 m-0 mb-2'>Easy Installation</p>
                                    <span className='text-secondary font4 d-block'>Our team handles the entire setup, ensuring your system is perfectly installed and ready for immediate use.</span>
                                    {/* <a href="" style={{color:'#2FB95D',fontSize:'12px'}}>Read more...</a> */}
                                </div>
                            </div>

                            <div className='d-flex align-items-center py-2 mt-3' style={{borderRadius:'10px'}}>
                                <img src={require('../images/service6.png')} alt="" />
                                <div className='ms-3'>
                                <p className='fw-bold font3 m-0 mb-2'>Continuous Support</p>
                                    <span className='text-secondary font4 d-block'>Enjoy ongoing assistance with routine maintenance and tips to keep your  hydroponic garden flourishing all year round.</span>
                                    {/* <a href="" style={{color:'#2FB95D',fontSize:'12px'}}>Read more...</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>

            </div>

{/* Layout 4 */}
<div className='mt-5 py-5 d-flex flex-column align-items-center bg-white'>
                    <p className='mb-2' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')} alt="" /><span className='fw-bold mx-2 mx-md-4'>BASIC REQUIREMENTS</span><img src={require('../images/download.png')} alt="" /></p>
                
                    <p className='font1 about-us-head m-0'>Essentials for plant growth</p>

                    <div className="row m-0 w-100 mt-4 container justify-content-evenly">
                        <div className="col-md-6 col-lg-3">
                            <div className='d-flex align-items-center flex-column justify-content-center p-3 py-4' style={{borderRadius:'15px',border:'0.2px solid lightgray',backgroundColor:'#ECFAEA',height:'300px'}}>
                                    <p className='fw-bold font3 m-0 mb-2 text-center'>Nutrient</p>
                                    <span className='text-secondary font4 text-center'>Bloomindoor gardens thrive on specialized nutrients mixed in specific proportions. Simply dilute our nutrient concentrates with water, and refill every 10-15 days to keep your plants nourished.</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <div className='d-flex align-items-center flex-column mt-3 mt-md-0 justify-content-center p-3 py-4' style={{borderRadius:'15px',border:'0.2px solid lightgray',backgroundColor:'#ECFAEA',height:'300px'}}>
                                    <p className='fw-bold font3 m-0 mb-2 text-center'>Light</p>
                                    <span className='text-secondary font4 text-center'>Our powerful LED grow lights mimic natural sunlight, providing essential red and blue spectra for optimal plant growth. Easily adjustable, they ensure plants get the right light intensity at all stages.</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <div className='d-flex align-items-center flex-column mt-3 mt-md-0 justify-content-center p-3 py-4' style={{borderRadius:'15px',border:'0.2px solid lightgray',backgroundColor:'#ECFAEA',height:'300px'}}>
                                    <p className='fw-bold font3 m-0 mb-2 text-center'>Carbon Dioxide</p>
                                    <span className='text-secondary font4 text-center'>Plants absorbs exhaled CO2 and release fresh oxygen, enhancing the air quality and creating a healthier indoor atmosphere.</span>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-lg-3">
                        <div className='d-flex align-items-center flex-column mt-3 mt-md-0 justify-content-center p-3 py-4' style={{borderRadius:'15px',border:'0.2px solid lightgray',backgroundColor:'#ECFAEA',height:'300px'}}>
                                    <p className='fw-bold font3 m-0 mb-2 text-center'>Water</p>
                                    <span className='text-secondary font4 text-center'>Hydrate your Bloom Indoor garden with clean, fresh water. Regular watering, combined with our nutrient solution, ensures plants receive essential hydration for vibrant growth.</span>
                            </div>
                        </div> */}
                    </div>
                </div>

{/* Layout 5 */}
<div className='d-flex align-items-center py-5' style={{backgroundColor:'#F2F7EB'}}>
<div className="row container m-0 mx-auto">
    <div className="col-md-6 bg-white d-flex justify-content-end ps-md-0" style={{borderRadius:'25px 0px 0px 25px'}}>
        <img src={require('../images/service8.png')} style={{width:'100%'}} alt="" />
    </div>
    <div className="col-md-6 bg-white pt-5 mt-4 mt-md-0" style={{borderRadius:'0px 25px 25px 0px'}}>
        <div>
            <p className='font1 text-dark px-4 text-center m-0 mb-2' style={{fontSize:'26px',fontWeight:700,lineHeight:'31px'}}>Let's Make Your Home Beautiful</p>
            <p className='font2 text-secondary text-center px-4' style={{fontSize:'14px'}}>March ahead on your journey to agripreneurship with us. Fill this form & our team will get in touch with you in 24 hours.</p>
            
            <div className="row m-0 mt-5">
                
                <div className="col-md-6 mt-4 mt-md-0">
                    <input type="text" value={first_name} onChange={(e)=>
                        setfirst_name(e.target.value)
                    } className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='First Name*'/>
                </div>
                <div className="col-md-6">
                <input type="text" value={last_name} onChange={(e)=>
                        setlast_name(e.target.value)
                    } className='form-control py-2 input shadow-none' style={{border:'0.9px solid #8BC634'}}  placeholder='Last Name*'/>
                    
                </div>
                <div className="col-12 mt-4">
                    <input type="email" value={email} onChange={(e)=>
                        setemail(e.target.value)
                    } className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Email*'/>
                </div>
                <div className="col-12 mt-4">
                    <input type="tel" maxLength={10} value={phone_number} onChange={(e)=>
                        setphone_number(e.target.value)
                    } className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Phone Number*'/>
                </div>
                <div className="col-12 mt-4">
                    <textarea type="text" value={message} onChange={(e)=>
                        setmessage(e.target.value)
                    } className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Message*'/>
                </div>
                <div className="col-12 mt-4 text-center pb-3">
                    <button onClick={send_data} className='btn btn-sm text-white fw-medium rounded-pill px-4 py-2' style={{backgroundColor:'#8BC634'}}>
                    <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                    <span className={`${load ? 'ms-2':'d-none'}`} role="status">Sending...</span>
                    <span className={load ? 'd-none':''}>Contact Us</span>
                    </button>
                </div>
            </div>
           
        </div>
    </div>
</div>
</div>

{/* Layout 6 */}
<div className='pb-5 pt-3' style={{backgroundColor:'#F2F7EB'}}>
<div className='container'>
                <div className=' py-3 pt-4'>
                <p className='mb-2 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')} alt="" /> <span className='fw-bold mx-3 mx-md-4'>FAQ</span> <img src={require('../images/download.png')} alt="" /></p>
                
                <p className='font1 about-us-head m-0 text-center mb-5'>Unlocking Answers to Your Questions</p>


                    <div className=''>
                    <p className='d-flex justify-content-between rounded-pill px-4 align-items-center m-0 py-3' style={{cursor:'pointer',border:'1px solid #75C634',backgroundColor:'transparent'}} onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}><span className='privacy-policy-text'>How do I get started with a hydroponic system?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#000"/>
      </svg></span></p>
              
      <p></p>
      <Collapse in={open} className=''>
        <div id="example-collapse-text">
         <p className='px-4 pb-2 fw-medium'>To start with a Hydroponic system, schedule an initial consultation to choose the right system for your needs. Bloomindoor will help with installation, provide training on system maintenance, and offer ongoing support for successful gardening.</p>
        </div>
      </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between rounded-pill px-4 align-items-center m-0 py-3' style={{cursor:'pointer',border:'1px solid #75C634',backgroundColor:'transparent'}} onClick={() => setOpen2(!open2)}
        aria-controls="example-collapse-text"
        aria-expanded={open2}><span className='privacy-policy-text'>How do Bloomindoor systems differ from traditional soil gardens?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#000"/>
      </svg></span></p>
              
      <p></p>
      <Collapse in={open2} className=''>
        <div id="example-collapse-text">
        <p className='px-4 pb-2 fw-medium'>We use hydroponics, specifically aeroponics, which involves growing plants in a nutrient-rich water solution instead of soil. This method promotes faster growth, uses less water, and requires minimal maintenance compared to traditional soil gardening.</p>
         
        </div>
      </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between rounded-pill px-4 align-items-center m-0 py-3' style={{cursor:'pointer',border:'1px solid #75C634',backgroundColor:'transparent'}} onClick={() => setOpen3(!open3)}
        aria-controls="example-collapse-text"
        aria-expanded={open3}><span className='privacy-policy-text'>What maintenance is required for BloomIndoor systems?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#000"/>
      </svg></span></p>
              
      <p></p>
      <Collapse in={open3} className=''>
        <div id="example-collapse-text">
        <p className='px-4 pb-2 fw-medium'>Maintenance of our systems is minimal but includes regular checking of water levels, nutrient concentration, and cleaning the system to prevent algae growth. We  provide guidance and support to help with these tasks.</p>
         
        </div>
      </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between rounded-pill px-4 align-items-center m-0 py-3' style={{cursor:'pointer',border:'1px solid #75C634',backgroundColor:'transparent'}} onClick={() => setOpen4(!open4)}
        aria-controls="example-collapse-text"
        aria-expanded={open4}><span className='privacy-policy-text'>Can I cultivate various plants in a single system?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#000"/>
      </svg></span></p>
              
      <p></p>
      <Collapse in={open4} className=''>
        <div id="example-collapse-text">
        <p className='px-4 pb-2 fw-medium'>Yes, Bloomindoor systems are versatile and can support a variety of plants, including flowers, herbs in the same system. You can customize your setup based on the plants you wish to grow.</p>
          
        </div>
      </Collapse>
                    </div>

                    <div className=''>
                    <p className='d-flex justify-content-between rounded-pill px-4 align-items-center m-0 py-3' style={{cursor:'pointer',border:'1px solid #75C634',backgroundColor:'transparent'}} onClick={() => setOpen5(!open5)}
        aria-controls="example-collapse-text"
        aria-expanded={open5}><span className='privacy-policy-text'>What happens if I encounter problems with my system?</span> <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M14.375 20.625H15.625V15.625H20.625V14.375H15.625V9.375H14.375V14.375H9.375V15.625H14.375V20.625ZM15.0041 26.25C13.4484 26.25 11.9858 25.9548 10.6163 25.3644C9.24687 24.774 8.05562 23.9727 7.0425 22.9606C6.02938 21.9485 5.2274 20.7583 4.63656 19.39C4.04552 18.0219 3.75 16.5599 3.75 15.0041C3.75 13.4484 4.04521 11.9858 4.63562 10.6162C5.22604 9.24687 6.02729 8.05562 7.03937 7.0425C8.05146 6.02937 9.24167 5.2274 10.61 4.63656C11.9781 4.04552 13.4401 3.75 14.9959 3.75C16.5516 3.75 18.0142 4.04521 19.3838 4.63563C20.7531 5.22604 21.9444 6.02729 22.9575 7.03938C23.9706 8.05146 24.7726 9.24167 25.3634 10.61C25.9545 11.9781 26.25 13.4401 26.25 14.9959C26.25 16.5516 25.9548 18.0142 25.3644 19.3837C24.774 20.7531 23.9727 21.9444 22.9606 22.9575C21.9485 23.9706 20.7583 24.7726 19.39 25.3634C18.0219 25.9545 16.5599 26.25 15.0041 26.25ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z" fill="#000"/>
      </svg></span></p>
              
      <p></p>
      <Collapse in={open5} className=''>
        <div id="example-collapse-text">
        <p className='px-4 pb-2 fw-medium'>If you encounter problems with your system, you can contact customer support for troubleshooting assistance. We offer support to resolve issues immediately and ensure your system operates smoothly.</p>
          
        </div>
      </Collapse>
                    </div>
               
                </div>
            </div>
</div>

            <Footer/>
        </div>

        <Backtotop/>
    </div>
  )
}

export default Our_Services