import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Backtotop from './Backtotop'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from './Footer'
import axios from 'axios'
import { ipaddress } from '../App'

const Blog_view_page = () => {
    const navigate=useNavigate()

    const {id}=useParams()

    const[detail,setdetail]=useState({})
    const[paragraphs,setparagraphs]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress}/ParagraphView/${id}/?token=sfdhgf`)
        .then((r)=>{
            console.log("Blog detail",r.data);
            setdetail(r.data)
            setparagraphs(r.data.paragraphs)
        })
    },[])

  return (
    <div>
        <div className='animate__animated animate__fadeIn' style={{backgroundColor:'#F2F7EB'}}>
            <Navbar state={"blog"}/>

            <div className='container mt-4 pb-1 main-container'>
                <p style={{color:'#0A5E1B',cursor:'pointer',fontWeight:500}} onClick={()=>{
                    navigate('/blog')
                }} className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg> <span className='ms-2'>Blog</span></p>
                <img className='blog-image images' src={detail.image} style={{width:'100%',borderRadius:'20px'}} alt="" />

{/* Layout 1 */}
                <div className='d-flex flex-column justify-content-center mt-4'>
        <p><span className='fw-bold m-0' style={{color:'#0A5E1B',fontSize:'15px'}}>DESIGN TO CODE </span><span className='bg-success-subtle p-1 rounded px-2 ms-3' style={{fontSize:'14px'}}>{detail.created_at && detail.created_at.slice(0,10)}</span></p>
                            <p className='font6 fw-bold m-0 mb-2' style={{fontSize:'28px',lineHeight:'34px'}}>{detail.title}</p>
                            <p className='font7' style={{lineHeight:'28px',fontWeight:400}}>{detail.description}</p>
           
        </div>

{/* Layout 2 */}
        <div className='bg-white mt-4 p-3 mb-4'>
            <p className='font7' style={{fontWeight:600}}>Table of Contents</p>
            <p className='font7 mt-4' style={{color:'#5E6A86'}}>Introduction</p>
            <p className='font7' style={{color:'#5E6A86'}}>Zero Friction</p>
            <p className='font7' style={{color:'#5E6A86'}}>AI Powered</p>
            <p className='font7' style={{color:'#5E6A86'}}>View Code & Digest Dynamic Data</p>
            <p className='font7' style={{color:'#5E6A86'}}>Share & Collaborate Seamlessly with Locofy Builder</p>
            <p className='font7' style={{color:'#5E6A86'}}>Direct Export & Deploy</p>
            <p className='font7' style={{color:'#5E6A86'}}>Conclusion</p>

        </div>

{/* Layout3 */}
<div className='mt-5 pt-4 border-top'>
    <p className='font7 fw-bold' style={{fontSize:'24px',lineHeight:'28px'}}>Introduction</p>
    
    {paragraphs.map((x,index)=>{
        return(
            <div className=''>
                {x.subheading &&(<p className='fw-bold mt-4' style={{fontSize:'18px'}}>{index+1}) {x.subheading}</p>)}
                <p className='font7' style={{lineHeight:'28px',letterSpacing:'1%'}}>{x.content}</p>
                <div>

                {x.points && x.points.map((z)=>{
                    return(
                        <div className='mt-4'>
                            <p className='fw-bold ms-3 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
</svg><span className='ms-2'>{z.heading && z.heading}</span></p>
                            <p className='ms-3'>{z.point && z.point}</p>
                        </div>
                    )
                })}
                </div>
                
                {x.images && x.images.map((y)=>{
                    return(
                        <div className='zoom3'>
                        <img src={y.image} className='my-4 images' style={{width:'100%',height:'319px',borderRadius:'15px'}} alt="" />
                    </div>
                    )
                })}
            </div>
        )
    })}
    </div>

            </div>

        <Footer/>
        </div>

        <Backtotop/>
    </div>
  )
}

export default Blog_view_page