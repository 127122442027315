import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Backtotop from './Backtotop'

const About_us = () => {
  return (
    <div>
        <div className='animate__animated animate__fadeIn' style={{backgroundColor:'#F2F7EB'}}>
            <Navbar state={"about-us"}/>

            <div className="container mt-5 pb-5 main-container">
                <div className="row m-0">
                    <div className="col-md-8 pe-4">
                        <p className='fw-bold mb-4' style={{color:'#0A5E1B',fontSize:'15px'}}>ABOUT US</p>
                        <p className='about-us-head font1'>Green Innovation for Modern Living</p>

                        <div className='mt-4 pe-4'>
                            <p className='font2' style={{color:'#999999',lineHeight:'29.6px',fontSize:'16px'}}>Welcome to Bloom Indoor, where we bring the beauty of gardens into your home, whether indoors or outdoors. Founded by Mr. N.H. Venkatesh, an aerospace engineer turned hydroponics innovator, Bloom Indoor is dedicated to making gardening accessible to everyone, even in urban apartments or closed spaces. We specialize in high-quality indoor plants, stylish planters, and expert plant care services.</p>

                            <p className='font2 mt-4' style={{color:'#999999',lineHeight:'29.6px',fontSize:'16px'}}>Our curated selection includes resilient, air-purifying plants that enhance any space. With a commitment to sustainability and eco-friendly products, we aim to create healthier, greener environments. Whether you’re a beginner or a seasoned plant parent, our knowledgeable team is here to support you every step of the way.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src={require('../images/about1.jpg')} style={{width:'100%',borderRadius:'20px'}} alt="" />
                    </div>
                </div>

{/* Layout 2 */}
                <div className='mt-5 pt-5 d-flex flex-column align-items-center'>
                    <p className='mb-4' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-2 mx-md-4'>OUR MISSION</span> <img src={require('../images/download.png')}/></p>
                
                    <p className='font1 about-us-head m-0'>Growing Happiness, One Plant at a Time</p>

                    <p className='font2 mt-2' style={{color:'#999999',lineHeight:'29.6px',fontSize:'16px'}}>At Bloom Indoor, our mission is to enrich lives by bringing the beauty of nature indoors. We provide high-quality plants and sustainable products that enhance well-being and aesthetics. Our commitment to sustainability and quality drives us to source plants responsibly and offer eco-friendly products. We strive to educate and inspire our community, building a deeper connection with nature and promoting the well-being that comes with it.</p>

                    <img className='mt-4' src={require('../images/about2.png')} style={{width:'90%'}} alt="" />
                </div>

{/* Layout 3 */}
<div className='mt-5 pt-5 d-flex flex-column align-items-center'>
                    <p className='mb-4' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-2 mx-md-4'>CORE VALUES</span> <img src={require('../images/download.png')}/></p>
                
                    <p className='font1 about-us-head m-0'>Discover the Path to Happiness as You</p>
                    <p className='font1 about-us-head'>Explore Our Gallery</p>

                    <div className="row m-0 w-100 mt-4">
                        <div className="col-md-6 p-3">
                            <div className='bg-white d-flex align-items-center p-3' style={{borderRadius:'10px'}}>
                                <img src={require('../images/about3.png')} alt="" />
                                <div className='ms-3'>
                                    <p className='fw-bold font3 m-0 mb-2'>Commitment to Excellence</p>
                                    <span className='text-secondary font4'>We are dedicated to delivering exceptional quality in every service we provide.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-3">
                            <div className='bg-white d-flex align-items-center p-3' style={{borderRadius:'10px'}}>
                                <img src={require('../images/about4.png')} alt="" />
                                <div className='ms-3'>
                                    <p className='fw-bold font3 m-0 mb-2'>Transparency</p>
                                    <span className='text-secondary font4'>We ensure clear and open communication, building trust and keeping you informed.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-3">
                            <div className='bg-white d-flex align-items-center p-3' style={{borderRadius:'10px'}}>
                                <img src={require('../images/about5.png')} alt="" />
                                <div className='ms-3'>
                                    <p className='fw-bold font3 m-0 mb-2'>Putting You First</p>
                                    <span className='text-secondary font4'>You are at the center of everything we do, from understanding to exceeding your needs.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-3">
                            <div className='bg-white d-flex align-items-center p-3' style={{borderRadius:'10px'}}>
                                <img src={require('../images/about6.png')} alt="" />
                                <div className='ms-3'>
                                    <p className='fw-bold font3 m-0 mb-2'>Ethical Integrity</p>
                                    <span className='text-secondary font4'>Our commitment to integrity guides us in building lasting relationships based on trust and respect.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

{/* Layout 4 */}
<div className='mt-5 pt-5'>
                    <p className='mb-5 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-2 mx-md-4'>OUR HISTORY</span> <img src={require('../images/download.png')}/></p>
                <div className='about-us-image row m-0'>
                    <div className="col-md-5 text-end p-0" style={{position:'relative'}}>
                        <img src={require('../images/K333 copy.png')} className='user-img mt-3 mt-md-0' alt="" />
                    </div>
                    <div className="col-md-7 p-0 mt-4 mt-md-0">
                        <div className='about-sub-div p-4 d-flex align-items-center justify-content-center'>
                            <p className='text-white word-break m-0 text-center'>
                            CEO of Bloomindoor, Mr N.H.Venkatesh, while living in an apartment felt an urge for having a flower
gardens in his apartment to make the living space more beautiful and also to make the interior
environment healthier. After intense R&amp;D in Hydroponics CEO was awarded a US patent 2017 for a
Hydroponic system which allows us to grow plants without use of soil. After further R&amp;D,
Bloomindoor was founded in 2021 to provide indoor hydroponic gardens to apartments, homes and
other commercial establishments.
Our journey reflects our commitment to making indoor gardens accessible for everyone to enjoy and
experience the pleasure peace of colourful plants indoor and to foster deeper connection with
nature.
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <Footer/>
        </div>
        <Backtotop/>
    </div>
  )
}

export default About_us