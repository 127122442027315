import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Backtotop from './Backtotop'
import axios from 'axios'
import Slider from 'react-slick'
import { ipaddress } from '../App'


const Gallery = () => {

  
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // initialSlide: 0,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

      const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    const[all_images,setall_images]=useState([])
    const[categories,setcategories]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress}/ImagesView/?token=sfdhgf`)
        .then((r)=>{
            // console.log("All images",r.data)
            setall_images(r.data)
        })

        axios.get(`${ipaddress}/CategoryView/?token=sfdhgf`)
        .then((r)=>{
          // console.log("Categories",r.data);
          setcategories(r.data)
          if(r.data.length>0){
            fetch_first_category_images(r.data[0].id)
          }
        })
    },[])

    const[images,setimages]=useState([])
    const[category_images,setcategory_images]=useState([])
    const[cat_name,setcat_name]=useState('')
    
    const fetch_category_images=(id)=>{
      axios.get(`${ipaddress}/ImagesView/?category=${id}&token=sfdhgf`)
      .then((r)=>{
          // console.log("Category images",r.data)
          setimages(r.data)
      })
    }

    const fetch_first_category_images=(id)=>{
      axios.get(`${ipaddress}/ImagesView/?category=${id}&token=sfdhgf`)
      .then((r)=>{
          // console.log("Category images",r.data)
          setcategory_images(r.data)
      })
    }

    const settings3 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 3,
      speed: 2000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    const[state,setstate]=useState(false)

return (
    <div>
        <div className='animate__animated animate__fadeIn'>
            <Navbar state={"gallery"}/>
            <div className='container mt-5 main-container'>
            <p className='mb-4 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-1 mx-md-4'>GALLERY</span> <img src={require('../images/download.png')}/></p>
                
                <p className='font1 about-us-head m-0 text-center'>Immerse Yourself in Nature's Beauty</p>
                <p className='font1 about-us-head m-0 text-center'>Explore Our Blooming Gallery</p>
            
            {/* Images layout 1 */}
            <div className='mt-5 row m-0 container'>
           {all_images.length>4 ? (
             <Slider {...settings}>
             {all_images.map((x)=>{
                 return(
                     <div className="p-2">
                     <div className='' style={{height:'346px'}}>
                         <img src={x.image} className='rounded' style={{width:'100%'}} alt="" />
                     </div>
                    </div>
                 )
             })}
         
       </Slider>
           ):(
            <div className="row m-0 pb-5">
              {all_images.map((x)=>{
                return(
                  <div className="p-2 col-md-3">
                  <div className='move-up-container' style={{height:'346px'}}>
                      <img src={x.image} className='rounded' style={{width:'100%'}} alt="" />
                  </div>
                 </div>
                )
              })}
            </div>
           )}
                </div>

            {/* Images layout 2 */}
            <div className='mt-5 mt-md-3 pt-5 pt-md-0 row m-0 pb-5'>
                <div className='d-flex align-items-center mb-4'>
                <p className='m-0 font2 gal-img1' style={{fontWeight:700,fontSize:'20px',lineHeight:'34px'}}>{categories[0] && categories[0].category_name && categories[0].category_name.slice(0,15)}</p>
                <img className='gal-img2' src={require('../images/line.png')} alt="" />
                </div>
                   {category_images && category_images.slice(0,4).map((x)=>{
                    return(
                      <div className="col-lg-3">
                    <div className='zoom-container' style={{height:'289px'}}>
                        <img src={x.image} className='rounded' style={{width:'100%',height:'100%'}} alt="" />
                    </div>
                   </div>
                    )
                   })}
                </div>

                  {/* Images layout 3 */}
            <div className='mt-3 row m-0 pb-5'>
               <div className='d-flex align-items-center mb-4'>
                <p className='m-0 font2 gal-img3' style={{fontWeight:700,fontSize:'20px',lineHeight:'34px'}}>Equipment for Hydroponic Gardening</p>
                <img className='gal-img4' src={require('../images/line.png')} alt="" />
                </div>
              <div className='mt-2 row m-0 container slider-container'>
              {categories.map((x)=>{
                return(
                  <div className="col-lg-2">
                    <div onClick={()=>{
                          fetch_category_images(x.id)
                          setcat_name(x.category_name)
                          setTimeout(() => {
                            setstate(true)
                          }, 1000);
                        }} className='zoom2' style={{height:'184px',cursor:'pointer'}}>
                        <img src={x.image} style={{width:'100%',height:'100%',borderRadius:'6px'}} alt="" />
                        <p className='font2 m-0 text-center mt-2' style={{color:'#0A5E1B',fontWeight:500,lineHeight:'26px'}}>{x.category_name && x.category_name.slice(0,15)}</p>
                    </div>
                   </div>
                )
              })}
            {/* <Slider {...settings2}>
            {categories.map((x)=>{
                return(
                    <div className="p-3">
                    <div className='' style={{height:'184px'}}>
                        <img src={x.image} style={{width:'100%',height:'100%',borderRadius:'6px'}} alt="" />
                        <p className='font2 m-0 text-center mt-2' style={{color:'#0A5E1B',fontWeight:500,lineHeight:'26px'}}>{x.category_name}</p>
                    </div>
                   </div>
                )
            })}
        
      </Slider> */}
                </div>
                 
                </div>
            </div>
        <div>
            <Footer/>
        </div>
        </div>
        <Backtotop/>

        <div className={`p-4 ${state ? 'animate__animated animate__fadeIn':'d-none'}`} style={{backgroundColor:'rgba(0,0,0,0.6)',width:'100%',height:'100vh',position:'fixed',zIndex:10,top:0,left:0}}>
          <div className='bg-white h-100 row m-0 p-2' style={{borderRadius:'10px'}}>
            <div className='text-end'>
            <svg style={{cursor:'pointer'}} onClick={()=>{
              setstate(false)
            }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
            </div>

      <div>
      <p className='m-0 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-1 mx-md-4'>{cat_name && cat_name.toUpperCase()}</span> <img src={require('../images/download.png')}/></p>

<div className="slider-container container px-3 mt-4">
{images.length>3 ? (
<Slider {...settings3}>
{images.map((x)=>{
return(
  <div className="p-3">
            <div className='' style={{height:'300px'}}>
                <img src={x.image} style={{width:'100%',height:'100%',borderRadius:'6px'}} alt="" />
                {/* <p className='font2 m-0 text-center mt-2' style={{color:'#0A5E1B',fontWeight:500,lineHeight:'26px'}}>guyg</p> */}
            </div>
           </div>
)
})}
</Slider>
):(
<div className='row m-0'>
{images.map((x)=>{
  return(
    <div className="col-lg-3 p-3">
      <div className='' style={{height:'300px'}}>
                <img src={x.image} style={{width:'100%',height:'100%',borderRadius:'6px'}} alt="" />
                {/* <p className='font2 m-0 text-center mt-2' style={{color:'#0A5E1B',fontWeight:500,lineHeight:'26px'}}>guyg</p> */}
            </div>
    </div>
  )
})}
</div>
)}

</div>
      </div>
          </div>
        </div>
    </div>
  )
}

export default Gallery