import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Backtotop from './Backtotop'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ipaddress } from '../App'
import { useNavigate } from 'react-router-dom'

const Home = () => {

    const navigate=useNavigate()

    const[first_name,setfirst_name]=useState('')
    const[last_name,setlast_name]=useState('')
    const[email,setemail]=useState('')
    const[phone_number,setphone_number]=useState('')
    const[message,setmessage]=useState('')

    const[all_images,setall_images]=useState([])
    const[categories,setcategories]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress}/ImagesView/?token=sfdhgf`)
        .then((r)=>{
            console.log("All images",r.data)
            setall_images(r.data)
        })
},[])

    const[load,setload]=useState(false)

    const send_data=()=>{
       setload(true)

       setTimeout(() => {
        send()
       }, 1000);
    }

    const send=()=>{
        // console.log("Hi")
        axios.post(`${ipaddress}/ContactMessageView/?token=sfdhgf`,{
            firstname:first_name,
            lastname:last_name,
            email:email,
            phonenumber:phone_number,
            message:message
        })

        .then((r)=>{
            toast.success('Data sent successfully!',{
                autoClose:2000,
                theme: "colored",
                position:'top-center'
            })
            setload(false)
            setfirst_name('')
        setlast_name('')
        setemail('')
        setphone_number('')
        setmessage('')
        })
        .catch((err)=>{
            console.log(err)
            setload(false)
        })
    }

  return (
    <div>
        <div className='home-div animate__animated animate__fadeIn'>
            <Navbar state={"home"}/>
            <div className='container mt-5 main-container'>
                <div className="row m-0">
                    <div className="col-md-7">
                        <div className='d-flex flex-column justify-content-center'>
                            {/* <span className='py-2 px-3 bg-success-subtle rounded-pill home-btn d-flex justify-content-center' style={{fontSize:'14px',fontWeight:600,lineHeight:'32px',color:'#244030'}}>Transform Your</span> */}
                            <p className='mt-2 m-0'><span className='font2 home-head' style={{fontWeight:700,color:'#142e38'}}>Bring</span>
                            <span className='font2 home-head ms-3' style={{fontWeight:700,color:'#80c454'}}>Life</span>
                            <span className='font2 home-head ms-3' style={{color:'#193c3e'}}>to Your Space with</span></p>
                            <p className='font2 home-head' style={{color:'#193c3e'}}>Blooming Gardens</p>
                            <p className='home-txt' style={{lineHeight:'33px',color:'#142E38'}}>Transform your home and office with lively plants that uplift your mood and ease stress, creating a warm and inviting space 
                            </p>
                            <div className='mt-3 mt-md-3'>
                                <button id='home-btn' onClick={()=>{
                                    navigate('/gallery')
                                }} className='btn text-white btn-sm px-3 py-2 rounded-pill font2' style={{backgroundColor:'#81C554'}}>
                                    <img src={require('../images/btn.png')} alt="" />
                                    <span className='ms-3'>Explore Our Gardens</span></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 mt-5 mt-md-0">
                        <img src={require('../images/Plant Image.2ae3dfa9182c81b9b004.png')} style={{width:'100%'}} alt="" />
                    </div>
                </div>
            </div>

{/* Layout 2 */}
<div className='mt-5 pt-5 d-flex flex-column align-items-center container pb-5'>
                    <p className='mb-4' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-1 mx-md-4'>ABOUT BLOOM INDOOR</span> <img src={require('../images/download.png')}/></p>
                
                    <p className='font1 about-us-head m-0'>Experience the Joy of Indoor Gardening</p>

                    <p className='font2 mt-2 text-center' style={{color:'#999999',lineHeight:'29.6px',fontSize:'16px'}}>We help you create beautiful indoor gardens, regardless of space. Our simple yet effective systems make it easy to grow flowers and vegetables both indoors and outdoors, making gardening accessible to all.</p>
<div className='mt-4'>
    <button className='btn btn-sm text-white rounded-pill px-3 py-2' style={{backgroundColor:'#75C634'}} onClick={()=>{
        navigate('/about_us')
    }}><span className='me-2'>Learn More</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></button>
</div>
                    </div>

{/* Layout 3 */}
<div className='mt-5 pt-3 pt-md-5 container pb-2 pb-md-5'>
                    <p className='mb-4 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-1 mx-md-4'>OUR SERVICES</span> <img src={require('../images/download.png')}/></p>
                
                    <p className='font1 about-us-head m-0 text-center'>Bringing Nature To Your Doorstep</p>

                   <div className="row m-0 mt-5">
                    <div className="col-md-6 col-lg-4">
                        <div className='row m-0 w-100 py-3' style={{backgroundColor:'#D6E4E8',borderRadius:'15px',height:'142px'}}>
                            <div className="col-4 d-flex align-items-center">
                                <img src={require('../images/home3.png')} style={{width:'70%'}} alt="" />
                            </div>
                            <div className="col-8 d-flex flex-column justify-content-center">
                                <p className='font3 m-0' style={{fontSize:'22px',fontWeight:600,lineHeight:'30px'}}>Flower Garden</p>
                                <p className='m-0' style={{color:'#002140',fontSize:'13px',lineHeight:'20px'}}>Grow various flowers such as Gerbera, Chrysanthemum, Marigold, Dahlia, and Vinca using hydroponics.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-4 mt-md-0">
                        <div className='row m-0 w-100 py-3' style={{backgroundColor:'#D6E4E8',borderRadius:'15px',height:'142px'}}>
                            <div className="col-4 d-flex align-items-center">
                                <img src={require('../images/home4.png')} style={{width:'100%'}} alt="" />
                            </div>
                            <div className="col-8 d-flex flex-column justify-content-center">
                                <p className='font3 m-0' style={{fontSize:'22px',fontWeight:600,lineHeight:'30px'}}>Herbs Garden</p>
                                <p className='m-0' style={{color:'#002140',fontSize:'13px',lineHeight:'20px'}}>Cultivate herbs like Coriander, Mint, Spinach, and Methi, suitable for both indoors and outdoors.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-4 mt-md-0">
                        <div className='row m-0 w-100 py-3' style={{backgroundColor:'#D6E4E8',borderRadius:'15px',height:'142px'}}>
                            <div className="col-4 d-flex align-items-center">
                                <img src={require('../images/home5.png')} style={{width:'100%'}} alt="" />
                            </div>
                            <div className="col-8 d-flex flex-column justify-content-center">
                                <p className='font3 m-0' style={{fontSize:'22px',fontWeight:600,lineHeight:'30px'}}>Vegetable Garden</p>
                                <p className='m-0' style={{color:'#002140',fontSize:'13px',lineHeight:'20px'}}>Grow common vegetables like Capsicum, Ladies Finger, and Beans and much more.</p>
                            </div>
                        </div>
                    </div>
                    
                   </div>
                    </div>

{/* Layout 4 */}
<div className='mt-5 pt-5 container pb-5'>
                    <p className='mb-4 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-1 mx-md-4'>HOW IT’S WORKS</span> <img src={require('../images/download.png')}/></p>
                
                    <p className='font1 about-us-head m-0 text-center'>Unveiling The Magic Behind Our System</p>

                   <div className="row m-0 mt-5">
                    <div className="col-md-6 col-lg-4 d-flex flex-column justify-content-evenly">
                        <div className='row m-0 w-100 py-3'>
                            <div className="col-2">
                                <img src={require('../images/home7.png')} style={{width:'72px',height:'70px'}} alt="" />
                            </div>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0'>Plant Growth</p>
                                <p className='m-0 home-text2 mt-1'>Hydroponic systems ensure vibrant, healthy plants.</p>
                            </div>
                        </div>

                        <div className='row m-0 w-100 py-3 mt-4'>
                            <div className="col-2">
                                <img src={require('../images/home7.png')} style={{width:'72px',height:'70px'}} alt="" />
                            </div>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0'>Gardening Methods</p>
                                <p className='m-0 home-text2 mt-1'>Simple and user-friendly gardening experience with simple methods. Does not 
                                require specialist gardener.</p>
                            </div>
                        </div>

                        <div className='row m-0 w-100 py-3 mt-4'>
                            <div className="col-2">
                                <img src={require('../images/home7.png')} style={{width:'72px',height:'70px'}} alt="" />
                            </div>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0'>Ease of Growning</p>
                                <p className='m-0 home-text2 mt-1'>Very easy to grow your own garden. At the basic level, it only involves changing of water once in 15 days.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 my-4 my-md-0">
                     <img src={require('../images/home6.png')} style={{width:'100%'}} alt="" />
                    </div>
                    <div className="col-md-6 col-lg-4 d-flex flex-column justify-content-evenly">
                        <div className='row m-0 w-100 py-3'>
                            <div className="col-2">
                                <img src={require('../images/home7.png')} style={{width:'72px',height:'70px'}} alt="" />
                            </div>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0'>Easily Relocatable Garden Systems</p>
                                <p className='m-0 home-text2 mt-1'> Flower Garden can be easily arranged and rearranged as the garden is on wheels. In addition the plants in each pot can be quickly changed to enjoy a new look of the garden.</p>
                            </div>
                        </div>

                        <div className='row m-0 w-100 py-3 mt-4'>
                            <div className="col-2">
                                <img src={require('../images/home7.png')} style={{width:'72px',height:'70px'}} alt="" />
                            </div>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0'>Nutrient</p>
                                <p className='m-0 home-text2 mt-1'>No daily watering. Nutrient to be replaced once in 15 days. Easy arrangement for changing nutrient.</p>
                            </div>
                        </div>

                        <div className='row m-0 w-100 py-3 mt-4'>
                            <div className="col-2">
                                <img src={require('../images/home7.png')} style={{width:'72px',height:'70px'}} alt="" />
                            </div>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0'>LED Grow Light</p>
                                <p className='m-0 home-text2 mt-1'>LED Grow Lights enhance colour of flowers and the plant providing a lovely visual experience of flowers.</p>
                            </div>
                        </div>
                    </div>
                   </div>
                    </div>

{/* Layout 5 */}
<div className='mt-5 pt-0 pt-md-5 container pb-5'>
                    <p className='mb-4 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-1 mx-md-4'>WHO CAN BENEFIT</span> <img src={require('../images/download.png')}/></p>
                
                    <p className='font1 about-us-head m-0 text-center'>Blooming Opportunities for Ideal Clients</p>

                   <div className="row m-0 mt-5">
                    <div className="col-md-6 col-lg-3">
                        <div className='d-flex flex-column justify-content-center align-items-center border bg-white' style={{borderRadius:'15px',height:'214px'}}>
                            <div className='bg-success-subtle p-4 rounded-circle d-flex align-items-center justify-content-center' style={{height:'93px',width:'93px'}}>
                                <img src={require('../images/home8.png')} style={{height:'48px',width:'48px'}} alt="" />
                            </div>
                            <p className='m-0 mt-4' style={{fontWeight:500,fontSize:'18px',lineHeight:'21px',color:'#0A5E1B'}}>Apartments</p>
                        </div>

                    </div>
                    <div className="col-md-6 col-lg-3 mt-4 mt-md-0">
                        <div className='d-flex flex-column justify-content-center align-items-center border bg-white' style={{borderRadius:'15px',height:'214px'}}>
                            <div className='bg-success-subtle p-4 rounded-circle d-flex align-items-center justify-content-center' style={{height:'93px',width:'93px'}}>
                                <img src={require('../images/home8.png')} style={{height:'48px',width:'48px'}} alt="" />
                            </div>
                            <p className='m-0 mt-4' style={{fontWeight:500,fontSize:'18px',lineHeight:'21px',color:'#0A5E1B'}}>Grow At Home</p>
                        </div>

                    </div>
                    <div className="col-md-6 col-lg-3 mt-4 mt-md-0">
                        <div className='d-flex flex-column justify-content-center align-items-center border bg-white' style={{borderRadius:'15px',height:'214px'}}>
                            <div className='bg-success-subtle p-4 rounded-circle d-flex align-items-center justify-content-center' style={{height:'93px',width:'93px'}}>
                                <img src={require('../images/home8.png')} style={{height:'48px',width:'48px'}} alt="" />
                            </div>
                            <p className='m-0 mt-4' style={{fontWeight:500,fontSize:'18px',lineHeight:'21px',color:'#0A5E1B'}}>Corporate offices</p>
                        </div>

                    </div>
                    <div className="col-md-6 col-lg-3 mt-4 mt-md-0">
                        <div className='d-flex flex-column justify-content-center align-items-center border bg-white' style={{borderRadius:'15px',height:'214px'}}>
                            <div className='bg-success-subtle p-4 rounded-circle d-flex align-items-center justify-content-center' style={{height:'93px',width:'93px'}}>
                                <img src={require('../images/home8.png')} style={{height:'48px',width:'48px'}} alt="" />
                            </div>
                            <p className='m-0 mt-4' style={{fontWeight:500,fontSize:'18px',lineHeight:'21px',color:'#0A5E1B'}}>Software Companies</p>
                        </div>

                    </div>
                    <div className="col-md-6 col-lg-3 mt-4">
                        <div className='d-flex flex-column justify-content-center align-items-center border bg-white' style={{borderRadius:'15px',height:'214px'}}>
                            <div className='bg-success-subtle p-4 rounded-circle d-flex align-items-center justify-content-center' style={{height:'93px',width:'93px'}}>
                                <img src={require('../images/home8.png')} style={{height:'48px',width:'48px'}} alt="" />
                            </div>
                            <p className='m-0 mt-4' style={{fontWeight:500,fontSize:'18px',lineHeight:'21px',color:'#0A5E1B'}}>Hotels and Restaurants</p>
                        </div>

                    </div>
                    {/* <div className="col-md-6 col-lg-3 mt-4">
                        <div className='d-flex flex-column justify-content-center align-items-center border bg-white' style={{borderRadius:'15px',height:'214px'}}>
                            <div className='bg-success-subtle p-4 rounded-circle d-flex align-items-center justify-content-center' style={{height:'93px',width:'93px'}}>
                                <img src={require('../images/home8.png')} style={{height:'48px',width:'48px'}} alt="" />
                            </div>
                            <p className='m-0 mt-4' style={{fontWeight:500,fontSize:'18px',lineHeight:'21px',color:'#0A5E1B'}}>Restaurants</p>
                        </div>

                    </div>
                    <div className="col-md-6 col-lg-3 mt-4">
                        <div className='d-flex flex-column justify-content-center align-items-center border bg-white' style={{borderRadius:'15px',height:'214px'}}>
                            <div className='bg-success-subtle p-4 rounded-circle d-flex align-items-center justify-content-center' style={{height:'93px',width:'93px'}}>
                                <img src={require('../images/home8.png')} style={{height:'48px',width:'48px'}} alt="" />
                            </div>
                            <p className='m-0 mt-4' style={{fontWeight:500,fontSize:'18px',lineHeight:'21px',color:'#0A5E1B'}}>Healthcare Facilities</p>
                        </div>

                    </div>
                    <div className="col-md-6 col-lg-3 mt-4">
                        <div className='d-flex flex-column justify-content-center align-items-center border bg-white' style={{borderRadius:'15px',height:'214px'}}>
                            <div className='bg-success-subtle p-4 rounded-circle d-flex align-items-center justify-content-center' style={{height:'93px',width:'93px'}}>
                                <img src={require('../images/home8.png')} style={{height:'48px',width:'48px'}} alt="" />
                            </div>
                            <p className='m-0 mt-4' style={{fontWeight:500,fontSize:'18px',lineHeight:'21px',color:'#0A5E1B'}}>Educational Institutions</p>
                        </div>

                    </div> */}
                   </div>
                    </div>

{/* Layout 6 */}
<div className="container mt-5">
<div className="row m-0 justify-content-between">
<div className="col-md-4">
                        <img src={require('../images/home9.jpg')} style={{width:'100%',borderRadius:'30px'}} alt="" />
                    </div>
                    <div className="col-md-7 mt-5 mt-md-0">
                        <p className='fw-bold mb-4' style={{color:'#0A5E1B',fontSize:'15px'}}><span className='me-5'>OUR MISSION</span><img src={require('../images/home10.png')} alt="" /></p>
                        <p className='about-us-head font1 m-0'>Transforming Spaces</p>
                        <p className='about-us-head font1'> with Gardens <img src={require('../images/home11.png')} alt="" /></p>

                        <div className='mt-4 pe-4'>
                            <p className='font2' style={{color:'#999999',lineHeight:'29.6px',fontSize:'16px'}}>Our mission is to provide a means for everyone to grow beautiful indoor flower gardens in closed spaces like apartments and offices. Additionally, we aim to offer effective growing systems for outdoor spaces, enabling people to cultivate their own vegetables and flowers, regardless of space constraints.</p>
                        </div>

                        <div className='mt-4'>
    {/* <button className='font2 btn btn-sm text-white rounded-pill px-4 py-2' style={{backgroundColor:'#2FB95D'}}><span className='me-2'>Learn More</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></button> */}
</div>
                    </div>
                </div>
</div>

{/* Lyout 7 */}
<div className="container mt-5 pt-5">
<p className='mb-4 text-center' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-1 mx-md-4'>OUR GALLERY</span> <img src={require('../images/download.png')}/></p>
                
                <p className='font1 about-us-head m-0 text-center pb-4'>Experience the Serenity of Blooming Gardens</p>
<div className='mt-3 row m-0 pb-3'>
                  {all_images && all_images.slice(0,8).map((x)=>{
                    return(
                        <div className="col-lg-3">
                        <div className='move-up-container' style={{height:'289px'}}>
                            <img src={x.image} style={{width:'100%',height:'100%',borderRadius:'10px'}} alt="" />
                        </div>
                       </div>
                    )
                  })}
                </div>

               

                <div className='text-center mt-2'>
    <button onClick={()=>{
        navigate('/gallery')
    }} className='font2 btn btn-sm text-white rounded-pill px-4 py-2' style={{backgroundColor:'#81C554'}}><span className='me-2'>See More</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></button>
</div>
</div>

{/* Layout 8 */}
<div className="container mt-5 pt-4 pb-4">
<div className="row m-0">
                    <div className="col-md-6 pe-4 d-flex flex-column justify-content-center">
                        <p className='fw-bold mb-4' style={{color:'#0A5E1B',fontSize:'15px'}}>OUR CONTACT <img src={require('../images/download.png')} className='ms-4' alt="" /></p>
                        <p className='about-us-head font1'>Get in touch with us<img src={require('../images/home11.png')} alt="" /></p>

                        <div className='mt-4 pe-4'>
                            <p className='font2' style={{color:'#999999',lineHeight:'29.6px',fontSize:'16px'}}>Need help or have questions? Get in touch now for quick assistance. We're here to support you with everything you need.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className='p-4 bg-white' style={{backgroundColor:'#F1F9EB',borderRadius:'15px'}}>
            <p className='font1 text-dark px-4 pt-2 text-center m-0 mb-2' style={{fontSize:'26px',fontWeight:700,lineHeight:'31px'}}>Let's Make Your Home Beautiful</p>
            <p className='font2 text-secondary text-center px-4' style={{fontSize:'14px'}}>Start your Agripreneurship journey today. Fill out this form, and our team will reach out within 24 hours to get you started.</p>
            
            <div className="row m-0 mt-5">
                <div className="col-md-6 mt-4 mt-md-0">
                    <input type="text" value={first_name} onChange={(e)=>{
                        setfirst_name(e.target.value)
                    }} className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='First Name*'/>
                </div>
                <div className="col-md-6">
                <input type="text" value={last_name} onChange={(e)=>{
                        setlast_name(e.target.value)
                    }} className='form-control py-2 input shadow-none' style={{border:'0.9px solid #8BC634'}}  placeholder='Last Name*'/>
                    
                </div>
                <div className="col-12 mt-4">
                    <input type="email" value={email} onChange={(e)=>{
                        setemail(e.target.value)
                    }} className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Email*'/>
                </div>
                <div className="col-12 mt-4">
                    <input type="tel" maxLength={10} value={phone_number} onChange={(e)=>{
                        setphone_number(e.target.value)
                    }} className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Phone Number*'/>
                </div>
                <div className="col-12 mt-4">
                    <textarea type="text" value={message} onChange={(e)=>{
                        setmessage(e.target.value)
                    }} className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Message*'/>
                </div>
                <div className="col-12 mt-4 text-center pb-2">
                    <button onClick={send_data} className='btn btn-sm text-white fw-medium rounded-pill px-4 py-2' style={{backgroundColor:'#8BC634'}}>
                    <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                    <span className={`${load ? 'ms-2':'d-none'}`} role="status">Sending...</span>
                    <span className={load ? 'd-none':''}>Contact Us</span>
                    </button>
                </div>
            </div>
           
        </div>
                    </div>
                </div>
</div>

<Footer/>

        </div>
<Backtotop/>
    </div>
  )
}

export default Home