import React from 'react'
import Navbar from './Components/Navbar'
import './main.css'
import About_us from './Components/About-us'
// import Footer from './Components/Footer'
import Our_Services from './Components/Our_Services'
import Gallery from './Components/Gallery'
import Blog from './Components/Blog'
import Blog_view_page from './Components/Blog_view_page'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Components/Home'        
import Contact_us from './Components/Contact_us'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from './Components/Scrolltotop'


export const ipaddress="https://backendapi.bloomindoor.in"

const App = () => {
  return (
    <div>
      <BrowserRouter>
      <ScrollToTop/>
      <ToastContainer/>
      <Routes>
      <Route path='/' element={<Home/>}/>
        <Route path='/about_us' element={<About_us/>}/>
        <Route path='/contact_us' element={<Contact_us/>}/>
        <Route path='/our_services' element={<Our_Services/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/blog_view/:id' element={<Blog_view_page/>}/>

      </Routes>
      </BrowserRouter>

        {/* <About_us/> */}
        {/* <Our_Services/> */}
        {/* <Footer/> */}
        {/* <Gallery/> */}
        {/* <Blog/> */}
        {/* <Blog_view_page/> */}
    </div>
  )
}

export default App