import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Backtotop from './Backtotop'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ipaddress } from '../App'

const Blog = () => {
    const navigate=useNavigate()

    const[categories,setcategories]=useState([])
    const[blogs,setblogs]=useState([])
    const[blogs2,setblogs2]=useState([])
    const[blogs_detail,setblogs_detail]=useState({})

    useEffect(()=>{
        axios.get(`${ipaddress}/BlogCategoryView/?token=sfdhgf`)
        .then((r)=>{
            // console.log("Blog categories",r.data)
            setcategories(r.data)
            if(r.data.length>0){
            fetch_blogs(r.data[0].id)
            }
        })
    },[])

    const fetch_blogs=(id)=>{
        axios.get(`${ipaddress}/DisplayBlogsUnderCategory/?category_id=${id}&token=sfdhgf`)
        .then((r)=>{
            console.log("Blogs",r.data);
            setblogs(r.data)
            setblogs2(r.data)
            setblogs_detail(r.data[0])
        })

    }

    const[index1,setindex1]=useState(0)

  return (
    <div>
        <div className='animate__animated animate__fadeIn' style={{backgroundColor:'#F2F7EB'}}>
            <Navbar state={"blog"}/>

            <div className="container mt-5 main-container">
                <div className="row m-0">
                    <div className="col-md-5 pe-4">
                        <p className='fw-bold m-0 mb-2' style={{color:'#0A5E1B',fontSize:'15px'}}>DESIGN TO CODE</p>
                        <p className='about-us-head font6 fw-bold'>{blogs_detail && blogs_detail.title}</p>

                        <div className='mt-4 pe-4'>
                            <p className='font2' style={{color:'#000',lineHeight:'29.6px',fontSize:'16px'}}>{blogs_detail && blogs_detail.description}</p>
                            <p onClick={()=>{
                                navigate(`/blog_view/${blogs_detail && blogs_detail.id}`)
                            }} className='d-flex align-items-center m-0 mt-4' style={{color:'#0A5E1B',cursor:'pointer'}}><span className='me-2 font2'>Continue reading</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></p>
                             </div>
                    </div>
                    <div className="col-md-7">
                        <img className='images' src={blogs_detail && blogs_detail.image} style={{width:'100%',height:'397px',borderRadius:'15px'}} alt="" />
                    </div>
                </div>

{/* Layout 2 */}
                <div className='mt-5'>
                    <p className='font7 fw-medium' style={{fontSize:'24px',lineHeight:'28px'}}>Recent Post</p>

                    <div className='row m-0'>
                        {blogs && blogs.slice(0,2).map((x)=>{
                            return(
                                <div className="col-md-6 px-2">
                        <div className="bg-white row m-0" style={{borderRadius:'15px'}}>
                        <div className="col-md-5 p-0">
                            <img src={x.image} style={{width:'100%',height:'210px',borderRadius:'15px 0px 0px 15px'}} alt="" />
                        </div>
                        <div className="col-md-7 ps-4 py-2 pt-3 d-flex flex-column justify-content-center">
                            <p><span className='fw-bold m-0' style={{color:'#0A5E1B',fontSize:'15px'}}>DESIGN TO CODE </span><span className='bg-success-subtle p-1 rounded px-2 ms-3' style={{fontSize:'14px'}}>{x.created_at && x.created_at.slice(0,10)}</span></p>
                            <p onClick={()=>{
                                navigate(`/blog_view/${x.id}`)
                            }} className='font6 fw-bold m-0 mb-2' style={{fontSize:'28px',lineHeight:'34px',cursor:'pointer'}}>{x.title && x.title.slice(0,30)}</p>
                            <p className='font7' style={{lineHeight:'28px',fontWeight:400}}>{x.description && x.description.slice(0,45)}...</p>
                        </div>
                    </div>
                        </div>
                            )
                        })}
                    </div>
                </div>

                {/* Layout 3 */}
                <div className='mt-5'>
                    <p className='font7 fw-medium' style={{fontSize:'24px',lineHeight:'28px'}}>Discover more topics</p>

<div className='mb-4 d-flex align-items-center gap-3'>
    {categories.map((x,index)=>{
        return(
            <>
    <span onClick={()=>{
        setindex1(index)
        fetch_blogs(x.id)
    }} className={`rounded-pill border py-2 px-4 fw-medium bg-white ${index===index1 ? 'text-primary fw-medium border-primary':''}`} style={{lineHeight:'32px',fontSize:'15PX',cursor:'pointer'}}>{x.blog_category_name}</span>
            
            </>
        )
    })}
</div>

<p className={`${blogs2.length>0 ? 'd-none':''} text-center pt-3 fw-medium`}>No Blogs available under this category...</p>
                    <div className={`row m-0 pt-3 ${blogs2.length>0 ? '':'d-none'}`}>
                        {
                            blogs2 && blogs2.slice(0,3).map((x,index)=>{
                                return(
                                    <div className="col-md-6 col-lg-4">
                        <div class="card border-0 shadow-sm zoom-container" style={{height:'445px'}}>
  <img src={x.image} style={{height:'220px'}} class="card-img-top" alt="..."/>
  <div class="card-body mt-2">
  <p><span className='fw-bold m-0 card-title' style={{color:'#0A5E1B',fontSize:'15px'}}>DESIGN TO CODE </span><span className='bg-success-subtle p-1 rounded px-2 ms-3' style={{fontSize:'14px'}}>{x.created_at && x.created_at.slice(0,10)}</span></p>

  <p onClick={()=>{
                navigate(`/blog_view/${x.id}`)
            }} className='font6 fw-bold m-0 mb-2 card-text' style={{fontSize:'28px',lineHeight:'34px',cursor:'pointer'}}>{x.title && x.title.slice(0,40)}</p>

  <p className='font7' style={{lineHeight:'28px',fontWeight:400}}>{x.description && x.description.slice(0,100)}...</p>

  </div>
</div>
                        </div>
                                )
                            })
                        }
                    </div>

{/* Layout 5 */}
<div className='d-flex align-items-center py-5 mt-5' style={{backgroundColor:'#F2F7EB'}}>
<div className="row container m-0 mx-auto " style={{borderRadius:'10px'}}>
    <div className="col-md-6 bg-white d-flex justify-content-end p-0" style={{borderRadius:'10px 0px 0px 10px'}}>
        <img src={blogs_detail.image} style={{width:'100%',height:'278px',borderRadius:'10px 0px 0px 10px'}} alt="" />
    </div>
    <div className="col-md-6 bg-white mt-4 mt-md-0" style={{borderRadius:'0px 25px 25px 0px'}}>
        <div className='d-flex flex-column justify-content-center h-100'>
        <p><span className='fw-bold m-0' style={{color:'#0A5E1B',fontSize:'15px'}}>DESIGN TO CODE </span><span className='bg-success-subtle p-1 rounded px-2 ms-3' style={{fontSize:'14px'}}>{blogs_detail.created_at && blogs_detail.created_at.slice(0,10)}</span></p>
                            <p className='font6 fw-bold m-0 mb-2' style={{fontSize:'28px',lineHeight:'34px'}}>{blogs_detail.title && blogs_detail.title.slice(0,60)}</p>
                            <p className='font7' style={{lineHeight:'28px',fontWeight:400}}>{blogs_detail.description && blogs_detail.description.slice(0,180)}</p>
                            {/* <p className=''><span className='font2 text-secondary'>Posted by</span> <span className='font2 ms-2'>Justin Benito</span></p> */}
           
        </div>
    </div>
</div>
</div>
                </div>
                </div>

        <Footer/>
        </div>

        <Backtotop/>
    </div>
  )
}

export default Blog