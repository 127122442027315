import React from 'react'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate=useNavigate()

  return (
    <div>
        <div className='d-flex justify-content-end container'>
            <img src={require('../images/footer.png')} alt="" />
            <img src={require('../images/footer2.png')} alt="" />
        </div>
        <div className='pt-4' style={{backgroundColor:'#BDE4B7',borderRadius:'70px 70px 0px 0px'}}>
            <div className="row m-0 justify-content-center pb-3 pt-3">
                <div className="col-md-4 col-lg-2">
                    <img src={require('../images/logo.png')} alt="" />
                </div>

                <div className="col-md-4 col-lg-2 mt-4 mt-md-0">
                  <ul style={{listStyleType:'none'}}>
                  <li><span className='footer-li fw-bold'>Quick Links</span></li>
                    <li className='mt-3'><span className='footer-li' onClick={()=>{
                      navigate('/about_us')
                    }}>About Us</span></li>
                    <li className='mt-2'><span className='footer-li' onClick={()=>{
                      navigate('/our_services')
                    }}>Services</span></li>
                    <li className='mt-2'><span className='footer-li'>How It Works</span></li>
                    <li className='mt-2'><span className='footer-li' onClick={()=>{
                      navigate('/gallery')
                    }}>Gallery</span></li>

                  </ul>
                </div>

                <div className="col-md-4 col-lg-2 mt-4 mt-md-0">
                  <ul style={{listStyleType:'none'}}>
                  <li><span className='footer-li fw-bold'>Legal</span></li>
                    <li className='mt-3'><span className='footer-li'>T&C</span></li>
                    <li className='mt-2'><span className='footer-li'>Privacy Policy</span></li>
                    <li className='mt-2'><span className='footer-li'>Returns</span></li>
                    <li className='mt-2'><span className='footer-li'>Shipping</span></li>
                    <li className='mt-2'><span className='footer-li'>Cancellation</span></li>

                  </ul>
                </div>

                <div className="col-md-4 col-lg-2 mt-4 mt-md-0">
                  <ul style={{listStyleType:'none'}}>
                  <li><span className='footer-li fw-bold'>Follow Us</span></li>
                    <li className='mt-3'><span className='footer-li '>Twitter</span></li>
                    <li className='mt-2'><span className='footer-li'>Youtube</span></li>
                    <li className='mt-2'><span className='footer-li'>Pinterest</span></li>
                    <li className='mt-2'><span className='footer-li'>Instagram</span></li>
                    <li className='mt-2'><span className='footer-li'>Facebook</span></li>
                  </ul>
                </div>

                <div className="col-md-4 col-lg-2 mt-4 mt-md-0">
                  <ul style={{listStyleType:'none'}}>
                  <li><span className='footer-li fw-bold'>Support</span></li>
                    <li className='mt-3'><span className='footer-li' onClick={()=>{
                      navigate('/our_services')
                    }}>FAQs</span></li>
                    <li className='mt-2'><span className='footer-li' onClick={()=>{
                      navigate('/contact_us')
                    }}>Contact Us</span></li>
                  </ul>
                </div>


            </div>
            <div className='py-2 d-flex align-items-center justify-content-center' style={{backgroundColor:'#51AF2D'}}>
                <p className='text-white m-0'>© 2024 Plan A Plant All Rights Reserved</p>
            </div>
        </div>
    </div>
  )
}

export default Footer