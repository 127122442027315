import React, { useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Backtotop from './Backtotop'
import axios from 'axios'
import { ipaddress } from '../App'
import { toast } from 'react-toastify'

const Contact_us = () => {

    const[first_name,setfirst_name]=useState('')
    const[last_name,setlast_name]=useState('')
    const[email,setemail]=useState('')
    const[phone_number,setphone_number]=useState('')
    const[message,setmessage]=useState('')

    const[load,setload]=useState(false)

    const send_data=()=>{
       setload(true)

       setTimeout(() => {
        send()
       }, 1000);
    }

    const send=()=>{
        // console.log("Hi")
        axios.post(`${ipaddress}/ContactMessageView/?token=sfdhgf`,{
            firstname:first_name,
            lastname:last_name,
            email:email,
            phonenumber:phone_number,
            message:message
        })

        .then((r)=>{
            toast.success('Data sent successfully!',{
                autoClose:2000,
                theme: "colored",
                position:'top-center'
            })
            setload(false)
            setfirst_name('')
        setlast_name('')
        setemail('')
        setphone_number('')
        setmessage('')
        })
        .catch((err)=>{
            // console.log(err)
            setload(false)
        })
    }


  return (
    <div>
        <div className='animate__animated animate__fadeIn' style={{backgroundColor:'#F2F7EB'}}>
            <Navbar state={"contact_us"}/>
            <div className="container mt-5 main-container pb-4">
            <p className='mb-2 text-center m-' style={{color:'#0A5E1B',fontSize:'15px',letterSpacing:'1px'}}><img src={require('../images/download.png')}/> <span className='fw-bold mx-4'>CONTACT US</span> <img src={require('../images/download.png')}/></p>
                
                <p className='font1 about-us-head m-0 text-center mb-5'>Reach Out to Us for a Greener Tomorrow</p>


<div className="row m-0 py-4" style={{border:'2px solid #BDE4B7',borderRadius:'15px',backgroundColor:'#BDE4B766'}}>
<div className="col-md-6 d-flex flex-column justify-content-evenly ps-1 ps-md-5">
                        <div className='row m-0 w-100 py-3 pb-4 border-bottom'>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0 d-flex align-items-center' style={{color:'#0A5E1B'}}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg> <span className='ms-2 font1 fw-medium'>Phone</span></p>
                                <p className='m-0 home-text2 mt-2 ms-4' style={{color:'#0A5E1B',fontSize:'14px'}}>N.H.Venkatesh</p>
                                <p className='m-0 home-text2  ms-4' style={{color:'#0A5E1B',fontSize:'14px'}}>+91 9886362293</p>
                            </div>
                        </div>

                        <div className='row m-0 w-100 py-3 pb-4 border-bottom'>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0 d-flex align-items-center' style={{color:'#0A5E1B'}}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
</svg><span className='ms-2 font1 fw-medium'>Mail</span></p>
                                <p className='m-0 home-text2 mt-2 ms-4' style={{color:'#0A5E1B',fontSize:'14px'}}>venkatesh.narsipur@gmail.com</p>
                            </div>
                        </div>

                        <div className='row m-0 w-100 py-3 pb-4'>
                            <div className="col-10 ps-4 d-flex flex-column justify-content-center">
                                <p className='home-text m-0 d-flex align-items-center' style={{color:'#0A5E1B'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
</svg><span className='ms-2 font1 fw-medium'>Address</span></p>
                                <p className='m-0 home-text2 mt-2 ms-4 fw-medium' style={{color:'#0A5E1B',fontSize:'14px'}}>Venkatesh</p>
                                <p className='m-0 home-text2 mt-2 ms-4' style={{color:'#0A5E1B',fontSize:'14px'}}>P17, 25th Main,</p>
                                <p className='m-0 home-text2 mt-2 ms-4' style={{color:'#0A5E1B',fontSize:'14px'}}>1st Phase, JP Nagar,</p>
                                <p className='m-0 home-text2 mt-2 ms-4' style={{color:'#0A5E1B',fontSize:'14px'}}>Bengaluru, 560078.</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 ">
                    <div className='p-4' style={{backgroundColor:'#FFF',borderRadius:'15px'}}>
            <p className='font1 text-dark px-4 pt-2 text-center m-0 mb-2' style={{fontSize:'26px',fontWeight:700,lineHeight:'31px'}}>Let's Make Your Home Beautiful</p>
            <p className='font2 text-secondary text-center px-4' style={{fontSize:'14px'}}>March ahead on your journey to agripreneurship with us. Fill this form & our team will get in touch with you in 24 hours.</p>
            
            <div className="row m-0 mt-5">
                <div className="col-md-6 mt-4 mt-md-0">
                    <input type="text" value={first_name} onChange={(e)=>{
                        setfirst_name(e.target.value)
                    }} className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='First Name*'/>
                </div>
                <div className="col-md-6">
                <input type="text" value={last_name} onChange={(e)=>{
                        setlast_name(e.target.value)
                    }} className='form-control py-2 input shadow-none' style={{border:'0.9px solid #8BC634'}}  placeholder='Last Name*'/>
                    
                </div>
                <div className="col-12 mt-4">
                    <input type="email" value={email} onChange={(e)=>{
                        setemail(e.target.value)
                    }} className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Email*'/>
                </div>
                <div className="col-12 mt-4">
                    <input type="tel" maxLength={10} value={phone_number} onChange={(e)=>{
                        setphone_number(e.target.value)
                    }} className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Phone Number*'/>
                </div>
                <div className="col-12 mt-4">
                    <textarea type="text" value={message} onChange={(e)=>{
                        setmessage(e.target.value)
                    }} className='form-control py-2 input shadow-none'  style={{border:'0.9px solid #8BC634'}} placeholder='Type your Message*'/>
                </div>
                <div className="col-12 mt-4 text-center pb-2">
                    <button  onClick={send_data} className='btn btn-sm text-white fw-medium rounded-pill px-4 py-2' style={{backgroundColor:'#8BC634'}}>
                    <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                    <span className={`${load ? 'ms-2':'d-none'}`} role="status">Sending...</span>
                    <span className={load ? 'd-none':''}>Contact Us</span>
                    </button>
                </div>
            </div>
           
        </div>
                    </div>
                </div>
</div>
<Footer/>
        </div>
        <Backtotop/>
    </div>
  )
}

export default Contact_us