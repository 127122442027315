import React from 'react'
import { useNavigate } from 'react-router-dom'

const Navbar = ({state}) => {
    const navigate=useNavigate()
  return (
    <div>
        <div className='d-none d-lg-block' style={{position:'fixed',top:0,left:0,width:'100%',zIndex:4,backgroundColor:state==='gallery' ? '#ffff':'#F2F7EB'}}>
        <div className='container row m-0 mx-auto py-2'>
            <div className="col-2 d-flex align-items-center">
                <img src={require('../images/logo.png')} style={{width:'100%',cursor:'pointer'}} onClick={()=>{
                  navigate('/')
                }} alt="" />
            </div>

            <div className='col-10 p-0'>
                <ul style={{listStyleType:'none'}} className='h-100  align-items-center d-flex gap-5 w-75 ms-auto justify-content-end'>
                <li className={`${state==="home" ? 'navbar-condition':''}`} style={{cursor:'pointer'}} onClick={()=>{
                    navigate('/')
                }}><span className='navbar-li'>Home</span></li>
                    <li  className={`${state==="our-services" ? 'navbar-condition':''}`} style={{cursor:'pointer'}} onClick={()=>{
                    navigate('/our_services')
                }}><span className='navbar-li'>Our Services</span></li>
                    <li className={`${state==="gallery" ? 'navbar-condition':''}`} style={{cursor:'pointer'}} onClick={()=>{
                    navigate('/gallery')
                }}><span className='navbar-li'>Gallery</span></li>
                    <li className={`${state==="blog" ? 'navbar-condition':''}`} style={{cursor:'pointer'}} onClick={()=>{
                    navigate('/blog')
                }}><span className='navbar-li'>Blogs</span></li>
                    <li className={`${state==="about-us" ? 'navbar-condition':''}`} style={{cursor:'pointer'}} onClick={()=>{
                    navigate('/about_us')
                }}><span className='navbar-li'>About Us</span></li>
                    <li style={{cursor:'pointer'}}  onClick={()=>{
                        navigate('/contact_us')
                    }} className={`${state==="contact_us" ? 'navbar-condition':''}`}><span className='navbar-li'>Contact Us</span></li>

                </ul>
            </div>
        </div>
        </div>
        

{/* Mobile Navbar */}
<nav class="navbar navbar-light bg-transparent d-block d-md-none">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src={require('../images/logo.png')} width={110} alt=""/>
    </a>

    <svg data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
  <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5"/>
</svg>
  </div>
</nav>


{/* Offcanvas */}
<div class="offcanvas offcanvas-end navbaroffcanvas" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header d-flex justify-content-between">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel"><img src={require('../images/logo.png')} width={120} alt="" /></h5>
    <svg data-bs-dismiss="offcanvas" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#51AF2D" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
  </div>
  <div class="offcanvas-body">
    <div class="mt-3">
      <ul className='ps-0 d-flex flex-column gap-4 mt-4' style={{listStyleType:'none'}}>
        <li data-bs-dismiss="offcanvas" className='d-flex align-items-center py-2' onClick={()=>{
            navigate('/')
        }}><i class="fa-solid fa-house" style={{color:'#51AF2D'}}></i><span className='ms-3 font1 text-black fw-medium' style={{fontSize:'17px'}}>Home</span></li>
<li data-bs-dismiss="offcanvas" onClick={()=>{
            navigate('/our_services')
        }} className='d-flex align-items-center py-2'><i style={{color:'#51AF2D'}} class="fa-brands fa-servicestack"></i> <span className='ms-3 font1 text-black fw-medium' style={{fontSize:'17px'}}>Our Services</span></li>
<li data-bs-dismiss="offcanvas" onClick={()=>{
            navigate('/gallery')
        }} className='d-flex align-items-center py-2'><i class="fa-brands fa-envira" style={{color:'#51AF2D'}}></i> <span className='ms-3 font1 text-black fw-medium' style={{fontSize:'17px'}}>Gallery</span></li>
<li data-bs-dismiss="offcanvas" onClick={()=>{
            navigate('/blog')
        }} className='d-flex align-items-center py-2'><i class="fa-solid fa-blog" style={{color:'#51AF2D'}}></i> <span className='ms-3 font1 text-black fw-medium' style={{fontSize:'17px'}}>Blogs</span></li>
<li data-bs-dismiss="offcanvas" onClick={()=>{
            navigate('/about_us')
        }} className='d-flex align-items-center py-2'><i class="fa-solid fa-user" style={{color:'#51AF2D'}}></i> <span className='ms-3 font1 text-black fw-medium' style={{fontSize:'17px'}}>About Us</span></li>
<li data-bs-dismiss="offcanvas" onClick={()=>{
    navigate('/contact_us')
}} className='d-flex align-items-center py-2'><i class="fa-solid fa-phone" style={{color:'#51AF2D'}}></i><span className='ms-3 font1 text-black fw-medium' style={{fontSize:'17px'}}>Contact Us</span></li>
      </ul>
    </div>
  </div>
</div>
    </div>
  )
}

export default Navbar